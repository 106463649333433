import React from 'react'

function Footer() {
  return (
    <div className='footer flex justify-center items-start bg-[#256B4E] py-5 text-white text-left' style={{minHeight: '400px', width: '100%'}}>
      <section className="flex flex-col text-left mx-3" style={{width: '40%'}}>
        <div className='text-left w-full mb-3 py-3' style={{borderBottom: '1px solid white'}}>
          <p>About Us</p>
        </div>
        <div>
          <p>SylVote is an electronic voting website that allows for secure and efficient voting.
           </p>
        </div>
      </section>
      <section className="flex flex-col text-left mx-3" style={{width: '40%'}}>
        <div className='text-left w-full mb-3 py-3' style={{borderBottom: '1px solid white'}}>
          <p>Contact Us</p>
        </div>
        <div className="flex flex-col w-full items-start text-left justify-between h-40">
          <p className='text-left'>North Legon, Oriental Street</p>
 
          <p className='text-left'>+233560870567 / +233543211657</p>
          <p className='text-left'>info@sylvote.com</p>
        </div>
      </section>
    </div>
  )
}

export default Footer