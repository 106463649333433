import React from 'react'

function About() {
  return (
    <div className='flex flex-wrap m-auto' style={{width: '80%'}}>
      <h1>About Us</h1>
      <br />
<p>
SylVote is an electronic voting website that allows for secure and efficient voting. It is designed to work for a variety of voting scenarios, from small groups to large-scale elections. With SylVote, you can easily create and manage your own elections, track voter participation, and ensure the integrity of the voting process.
</p>
      <ul>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
      </ul>
    </div>
  )
}

export default About