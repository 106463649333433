import React, { useEffect, useState } from 'react'

import CardNominee from '../../Common/Cards/CardNominee';


import { useParams } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Nominee() {
    const { id } = useParams();

    const [nomData, setNomData] = useState([]);
    const [votePackages, setVotePackages] = useState([]);
 
    // let programId = '';
    const [isLoading, setLoading] = useState(false);

     
    useEffect(() => {
     
      fetch(`https://hstonline.tech/hstsms/api/v1/nominees/${id}`)
        .then(response => response.json())
        .then(data => {
          console.log('Fetched nominees data:', data);
          setNomData(data.data);
         
          fetch(`https://hstonline.tech/hstsms/api/v1/votePackages/${data.data.programId}`)
                    .then(response => response.json())
                    .then(data => {
                        console.log('Fetched votePackages data:', data);
                        setVotePackages(data);
                    })
                    .catch(error => {
                        console.error('Error fetching votePackages data:', error);
                    });
        })
        .catch(error => {
          console.error('Error fetching nominees data:', error);
        });

      
    }, []);
   
 
 
    useEffect(() => {
        setState(prevState => ({ ...prevState, nominee: nomData }));
      }, [nomData]);

    const [state, setState] = useState({
        votes: 0, cedi: 0, dollar: 0, num_votes: null, network :'',
        code:'',phoneNumber:'',
        nominee : nomData, 
     
      
    })

  

    const [paymentType, setPaymentType] = useState('')

    const handleOptionChange = (event) => {
        setPaymentType(event.target.value);
    };

  


    useEffect(()=>{
        setState({...state, 
           // cedi: state.nominee.voteCost  * state.num_votes ,
           cedi:  state.num_votes / 6 ,
            dollar: state.cedi / state.nominee.dollarRate
        })
    }, [state.num_votes, state.cedi])

 
   

    const handleSubmit = (event) => {
        event.preventDefault();

        setLoading(true);
        const dataToSend = {
          num_of_votes: state.num_votes,
          network: state.network,
          phoneNumber: state.phoneNumber,
          code: state.nominee.code,
          callback_url: `https://sylvote.com/nominee/${id}`
        };

       
       
        //fetch('http://127.0.0.1:8000/api/v1/voteOnline',
       fetch('https://hstonline.tech/hstsms/api/v1/voteOnline', 
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(dataToSend)
        })
          .then(response => response.json())
          .then(data => {
            console.log('Form submitted:', data);
            if (data.status === "success")
            {
                toast.success(data.message);
                const checkout_url = data.url;
                window.location.href = checkout_url;
            }
          else{
                toast.error(data.message)
          }
            // Handle the response data as needed

          })
          .catch(error => {
            console.error('Error submitting form:', error);
          })


          .finally(() => {
            setLoading(false);
          });
      };
      



    return (
        <section className='nominee m-auto py-14 flex justify-center items-start page'>
            <div className='sideone' style={{}}>
                <div className="w-full" style={{height: '80%'}}>
                    <CardNominee hideBtn data={state.nominee}/>
                </div>
                <ToastContainer />
                
            </div>
          
            <form action="" className='px-4 w-1/2' onSubmit={handleSubmit}>
                <div style={{background: `${paymentType === 'momo'?'rgb(0,23,100,.05)':'white'}`}} className="form1 flex w-full flex-col items-start mb-5 border-1 border-solid border-gray-200 p-3">
                    
                    {/* <div className="flex mb-2 py-2 w-full">
                        <input className='mr-3'  type="radio" name='paymentMethod' value='momo' checked={paymentType === 'momo'} onChange={handleOptionChange}/>
                        <h1 className='text-xl text-[#193762] font-bold'>Mobile Money</h1>
                    </div> */}
                    <div className="w-full p-2">
                        {/* <input value={state.num_votes }  onChange={(e)=>setState({...state, num_votes: e.target.value})} type="number" min="1" placeholder='Enter number of Votes' className='w-full pl-2' style={{border: '2px solid gray', height: '50px'}} required />
                     */}
                    <select onChange={(e)=>setState({...state, num_votes: e.target.value})} className='w-full pl-2' style={{border: '2px solid gray', height: '50px'}} required>
                    <option>Select Votes</option>
                     
                      {votePackages.map((option) => (
    <option key={option.id} value={option.num_of_votes}>
      {`${option.num_of_votes} VOTES GHC${option.amount}`}
    </option>
  ))}
                  
                    </select>
                    </div>
                    <div className="InputField flex w-full">
                        {/* <div className="w-1/2 m-2 flex flex-col items-start">
                            <h1 className='text-lg'>Network</h1>
                            <select name="network"  id="" className='py-2 w-full'  onChange={(e)=>setState({...state, network: e.target.value})} style={{border: '2px solid gray', height: '50px'}}  required>
                                <option value="">Select Network</option>
                                <option value="MTN">MTN</option>
                                <option value="ATL">AirtelTigo</option>
                                <option value="VDF">Vodafone</option>
                            
                            </select>
                        </div> */}
                        <div className="w-full p-2">
                            <h1 className='text-lg'>Mobile Number</h1>
                            <input style={{border: '2px solid gray', height: '50px'}}  onChange={(e)=>setState({...state, phoneNumber: e.target.value})} className='mr-3 w-full pl-2' type="number" placeholder='Enter Phone Number' name='phoneNumber' checked={paymentType === 'momo'} required/>
                        </div>

                        <input type="hidden" value={state.nominee.code} name="code"></input>
                      
                    </div>
                </div>

                <div className="nomineeState w-full h-full py-0 pr-3 flex flex-wrap justify-between">
                    <aside className="flex flex-col justify-between bg-[#0634a017] text-[#102647] py-2 px-4" style={{width: '31%'}}>
                        <h1>{state.num_votes ?? 0}</h1>
                        <b className='ml-2'>Votes</b>
                    </aside>
                    <aside className="flex flex-col justify-between bg-[#0634a017] text-[#102647] py-2 px-4" style={{width: '31%'}}>
                        <h1>Cedi (₵)</h1>
                        <b className='ml-2'>GHS {state.cedi && state.cedi >= 0.01 ? state.cedi : "0"}</b>
                    </aside>
                    <aside className="flex flex-col justify-between bg-[#0634a017] text-[#102647] py-2 px-4" style={{width: '31%'}}>
                        <h1>US Dollar ($)</h1>
                        <b className='ml-2'>USD { state.dollar && state.dollar >= 0.01 ? state.dollar.toFixed(2) : '0.00'}</b>
                    </aside>
                </div>
            

                {/* <div style={{background: `${paymentType === 'card'?'rgb(0,23,100,.05)':'white'}`}} className="form2 flex w-full flex-col items-start mb-5 border-1 border-solid border-gray-200 p-3">
                    <div className="flex py-2 w-full">
                        <input className='mr-3' type="radio" name='paymentMethod' value='card' checked={paymentType === 'card'} onChange={handleOptionChange}/>
                        <h1 className='text-lg'>Card Payment</h1>
                    </div>
                </div>  */}
           


                <div className="w-full">
        <button
          className="w-full bg-[#256B4E] h-14  hover:bg-green-800  rounded-md"
          disabled={isLoading} // Disable the button while loading
        
         // Call the vote function
        >
          {isLoading ? (
            <span className='text-white'><b> Loading... </b> </span> // Show a loading indicator
          ) : (
            <b className='text-white'>Vote Now</b> // Show the button label
          )}
        </button>
      </div>
            </form>
        </section>
    )
}

export default Nominee